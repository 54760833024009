import { Injectable } from "@angular/core";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tapResponse } from '@ngrx/operators';
import { Store } from "@ngrx/store";
import { AppInsightCustomerSearch } from "entities/app-insight-customer-search";
import { AppInsightCustomerSearchTerm } from "entities/app-insight-customer-search-term";
import { Branch } from "entities/branch";
import { CartResult } from "entities/cart-result";
import { Customer } from 'entities/customer';
import { CustomerSearch, MscPayerSearch } from 'entities/customer-search';
import { ShipToCustomer } from "entities/ship-to-customer";
import { ToastType } from "entities/toast-type";
import { blankId } from "helpers/blank-id";
import { isCashCustomer, isDefaultCustomerName } from "helpers/is-cash-customer";
import { isCCShipTo } from "helpers/is-cc-shipto";
import { CustomerModalComponent } from "modals/customer-modal/customer-modal.component";
import { CustomerNotesModalComponent } from "modals/customer-notes-modal/customer-notes-modal.component";
import { MscPayersModalComponent } from "modals/msc-payers-modal/msc-payers-modal.component";
import { ShippingAddressModalComponent } from 'modals/shipping-address/shipping-address-modal.component';
import { combineLatest, of } from "rxjs";
import { catchError, distinctUntilChanged, filter, finalize, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { CheckoutFormService } from "services/checkout-form.service";
import { CustomerService } from "services/customer.service";
import { LoaderService } from "services/loader.service";
import { LoggerService } from 'services/logger.service';
import { ModalService } from 'services/modal.service';
import { PaymentService } from "services/payment.service";
import { ToastService } from "services/toast.service";
import { AppState } from "store/app-state";
import * as BranchActions from 'store/branch/branch.actions';
import * as BranchSelectors from 'store/branch/branch.selectors';
import * as CartActions from "store/cart/cart.actions";
import * as CartSelectors from 'store/cart/cart.selectors';
import * as ConfigurationActions from 'store/configuration/configuration.actions';
import * as ConfigurationSelectors from 'store/configuration/configuration.selectors';
import * as CustomerActions from "store/customer/customer.actions";
import * as CustomerSelectors from 'store/customer/customer.selectors';


@Injectable()
export class CustomerEffects {
  loadDefaultCustomerFromConfiguration$ = createEffect(() =>
    this.action$.pipe(
      ofType(ConfigurationActions.getConfigurationSuccess),
      withLatestFrom(this.store.select(CustomerSelectors.selectedCustomer)),
      filter(([_, customer]) => !customer || !customer.customerNumber),
      map(([{ configuration }]) =>
        CustomerActions.selectCustomer({ customer: configuration.customer })
      )
    )
  );

  clearCustomer$ = createEffect(() =>
    this.action$.pipe(
      ofType(CustomerActions.clearCustomer, BranchActions.selectBranch),
      withLatestFrom(
        this.store.select(BranchSelectors.selectedBranch),
        this.store.select(ConfigurationSelectors.selectCustomer)
      ),
      // Get Shiptos after clearing customer
      filter(
        ([_, branch, __]) => branch?.code !== null && branch?.code !== undefined
      ),
      switchMap(([_, branch, defaultCustomer]) => {
        const customerSearch: CustomerSearch = {
          branchCode: branch.code,
          customerNumber: defaultCustomer?.customerNumber,
          customerType: "WE",
        };
        return this.customerService.searchCustomers(customerSearch).pipe(
          map((results: Customer[]) =>
            results.map((customer: Customer) =>
              isDefaultCustomerName(customer.customerName)
                ? {
                    ...customer,
                    customerName: "",
                    city: "",
                    state: "",
                    postalCode: "",
                    streetAddress: "",
                  }
                : customer
            )
          ),
          map((results: Customer[]) => {
            if (results.length === 1) {
              this.store.dispatch(
                CustomerActions.setShipToAddress({ shipToAddress: results[0] })
              );
            }
            this.store.dispatch(
              CustomerActions.loadShipToCustomersSuccess({
                customers: results,
                openShipToModal: false,
              })
            );
            return CustomerActions.setDefaultCustomer({ defaultCustomer });
          }),
          catchError(async (error) =>
            CustomerActions.loadShipToCustomersFailure()
          )
        );
      })
    )
  );

  showToast$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(CustomerActions.findCustomersFailed),
        tap(({ error, tracking }) => {
          if (tracking) {
            let userId: string = null;
            const sourceName = this.customerService.getSourceLocationType(
              tracking.customerNumber,
              tracking.parentSource
            );
            const metricName = this.loggerService.getMetricValue(sourceName);

            let customerSearchTerm: AppInsightCustomerSearchTerm = {
              customerNumber: tracking.customerNumber,
              customerName: tracking.customerName,
              city: tracking.searchCity,
              state: tracking.searchState,
              postalCode: tracking.searchPostalCode,
              phoneNumber: tracking.searchPhoneNumber,
              isFavourite: false,
            };

            let appInsightCustomerSearch: AppInsightCustomerSearch = {
              userId: userId,
              branchNumber: tracking.branchCode,
              searchTerm: tracking.searchTerm || "",
              customerSearchTerm: JSON.stringify(customerSearchTerm),
              source: sourceName,
              result: null,
              customerNotFound: true,
              plMetricName: sourceName,
            };

            this.loggerService.trackMetric(
              metricName,
              appInsightCustomerSearch
            );
          }

          this.toastService.showToast(error, ToastType.Error);
        })
      ),
    { dispatch: false }
  );

  getCustomerNotes = createEffect(
    () =>
      this.action$.pipe(
        ofType(CustomerActions.getCustomerNotes),
        tap(async ({ customerNumber }) => {
          const modalRef = this.ngModalService.open(
            CustomerNotesModalComponent
          );
          await modalRef.result;
        })
      ),
    { dispatch: false }
  );

  findCustomers$ = createEffect(() =>
    this.action$.pipe(
      ofType(CustomerActions.findCustomers),
      withLatestFrom(this.store.select(BranchSelectors.selectedBranch)),
      tap(() => (this.loaderService.loading = true)),
      switchMap(
        ([
          {
            parentSource,
            searchTerm,
            customerNumber,
            customerName,
            searchCity,
            searchState,
            searchPostalCode,
            searchPhoneNumber,
          },
          branch,
        ]) =>
          this.customerService
            .searchCustomers({
              customerType: "AG",
              customerNumber: customerNumber,
              customerName: customerName,
              city: searchCity,
              state: searchState,
              postalCode:
                searchPostalCode.length > 0
                  ? searchPostalCode.slice(0, 5) +
                    "*" +
                    searchPostalCode.slice(5)
                  : "",
              branchCode: branch.code,
              phoneNumber: searchPhoneNumber,
              accountGroups: ["Z001", "Z002"],
            })
            .pipe(
              map((customers) => customers.filter((c) => !isCCShipTo(c))),
              map((customers) => {
                if (customers.length === 0) {
                  return CustomerActions.findCustomersFailed({
                    error: "No Customer Found",
                    tracking: {
                      parentSource,
                      searchTerm,
                      customerNumber,
                      customerName,
                      searchCity,
                      searchState,
                      searchPostalCode,
                      searchPhoneNumber,
                      branchCode: branch.code,
                    },
                  });
                }

                if (
                  customers.length === 1 &&
                  customers[0].payerNotValidInBranch
                ) {
                  return CustomerActions.findCustomersFailed({
                    error: "Payer Not Valid",
                    tracking: {
                      parentSource,
                      searchTerm,
                      customerNumber,
                      customerName,
                      searchCity,
                      searchState,
                      searchPostalCode,
                      searchPhoneNumber,
                      branchCode: branch.code,
                    },
                  });
                }

                if (customers.length === 1) {
                  return CustomerActions.findCustomersSuccess({
                    customer: customers[0],
                    payers: [],
                  });
                }

                return CustomerActions.openCustomerModal({
                  customers,
                  payers: [],
                });
              }),
              catchError((error) =>
                of(
                  CustomerActions.findCustomersFailed({
                    error,
                    tracking: {
                      parentSource,
                      searchTerm,
                      customerNumber,
                      customerName,
                      searchCity,
                      searchState,
                      searchPostalCode,
                      searchPhoneNumber,
                      branchCode: branch.code,
                    },
                  })
                )
              ),
              tap(() => (this.loaderService.loading = false))
            )
      )
    )
  );

  openCustomerModal$ = createEffect(() =>
    this.action$.pipe(
      ofType(CustomerActions.openCustomerModal),
      switchMap(({ customers, payers }) =>
        this.modalService
          .open(CustomerModalComponent, { customers }, { size: "lg" })
          .pipe(
            map((customer) => {
              return CustomerActions.findCustomersSuccess({ customer, payers });
            })
          )
      )
    )
  );

  setCartBranchForQuote$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.loadCartForQuoteSuccess),
      withLatestFrom(
        this.store.select(BranchSelectors.selectBranchesForOrdering)
      ),
      filter(
        ([{ cartResult }, branches]: [{ cartResult: CartResult }, Branch[]]) =>
          !!cartResult && !!branches
      ),
      map(
        ([{ cartResult }, branches]: [
          { cartResult: CartResult },
          Branch[]
        ]) => {
          const branch = branches.find((b) => b.code === cartResult.branchCode);
          return BranchActions.selectBranchForQuote({
            branch,
            isPreventSelectCart: true,
          });
        }
      )
    )
  );

  setCartCustomerForQuote$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.loadCartForQuoteSuccess),
      withLatestFrom(
        this.store.select(BranchSelectors.selectedBranch),
        this.store.select(CustomerSelectors.selectShipTo)
      ),
      filter(([{ cartResult }, branch]) => !!cartResult && !!branch),
      switchMap(([{ cartResult }, branch, shipTo]) =>
        this.customerService
          .loadCustomer(cartResult.customerNumber, branch.code)
          .pipe(
            tap(() => {
              if (cartResult?.customerNumber !== shipTo?.customerNumber) {
                this.store.dispatch(CustomerActions.clearShipTo());
              }
            }),
            map((customer) => {
              this.store.dispatch(
                CustomerActions.getPayersForQuote({
                  branchCode: cartResult.branchCode,
                  customerNumber: customer.customerNumber,
                })
              );
              return CustomerActions.selectCustomer({ customer });
            })
          )
      )
    )
  );

  getCustomerPayersForQuote$ = createEffect(() =>
    this.action$.pipe(
      ofType(CustomerActions.getPayersForQuote),
      switchMap(({ branchCode, customerNumber }) =>
        this.customerService
          .searchCustomers({
            customerType: "RG",
            customerNumber: customerNumber,
            branchCode: branchCode,
          })
          .pipe(
            map((payers) =>
              CustomerActions.getPayersForQuoteSuccess({ payers })
            ),
            catchError((error) =>
              of(CustomerActions.getPayersFailed({ error }))
            )
          )
      )
    )
  );

  clearShipTo$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.submitQuoteSuccess, CartActions.submitOrderSuccess),
      map(() => CustomerActions.clearShipTo())
    )
  );

  cancelLoadShipTos$ = createEffect(() =>
    this.action$.pipe(
      ofType(CustomerActions.cancelLoadShipToCustomers),
      filter((action) => action.openShipToModal),
      map(() => CustomerActions.openShipToModal())
    )
  );

  loadShipTos$ = createEffect(() =>
    this.action$.pipe(
      ofType(
        CustomerActions.loadShipToCustomers,
        CartActions.selectOpenCartSuccess
      ),
      withLatestFrom(
        this.store.select(CustomerSelectors.selectedCustomer),
        this.store.select(BranchSelectors.selectedBranch),
        this.store.select(CustomerSelectors.selectShipToCustomers),
        this.store.select(CustomerSelectors.selectShipTo)
      ),

      filter(
        ([_, __, branch]) => branch?.code !== undefined && branch?.code !== null
      ),
      filter(([_, customer, __]) => !!customer),
      map(([action, customer, branch]) => {
        if (action.type === "[Cart] Select Open Cart Success") {
          return {
            customerSearch: {
              customerType: "WE",
              customerNumber: action.cartResult.customerNumber,
              branchCode: action.cartResult.branchCode,
            },
            openShipToModal: false,
          };
        } else {
          return {
            customerSearch: {
              customerType: "WE",
              customerNumber: action.customerNumber
                ? action.customerNumber
                : customer.customerNumber,
              branchCode: branch.code,
            },
            openShipToModal: action.openShipToModal,
          };
        }
      }),
      tap(() => (this.loaderService.loading = true)),
      switchMap(({ customerSearch, openShipToModal }) =>
        this.customerService
          .searchCustomers(customerSearch as CustomerSearch)
          .pipe(
            map((results: Customer[]) =>
              CustomerActions.loadShipToCustomersSuccess({
                customers: results.filter((c) => !isCCShipTo(c)),
                openShipToModal,
              })
            ),
            catchError(async (error) =>
              CustomerActions.loadShipToCustomersFailure()
            ),
            finalize(() => (this.loaderService.loading = false))
          )
      )
    )
  );

  loadShipTosSuccess$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(CustomerActions.loadShipToCustomersSuccess),
        tap((action) => {
          if (action.openShipToModal) {
            this.store.dispatch(CustomerActions.openShipToModal());
          }
        })
      ),
    { dispatch: false }
  );

  setCartPreselectedShiptoWhenAddingPartFromPartsBuyout$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.addItemToCartFromPartsBuyoutSuccess),
      withLatestFrom(
        this.store.select(CustomerSelectors.selectShipToCustomers).pipe(
          map((x) => {
            if (x.length === 1) {
              return x[0] as ShipToCustomer;
            }
            const defaultCustomer = x.find((c) => c.isDefault);
            if (defaultCustomer) {
              return defaultCustomer as ShipToCustomer;
            }
            return null;
          })
        )
      ),
      map(([_, shipToCustomer]) =>
        CartActions.updateShipTo({
          shipToCustomer,
          isEdited: false,
          isCartUpdate: true,
        })
      )
    )
  );

  preselectShiptoIfExternalUser$ = createEffect(() =>
    this.action$.pipe(
      ofType(CartActions.getCartDataSuccess),
      withLatestFrom(
        this.store.select(CustomerSelectors.selectShipToCustomers).pipe(
          map((x) => {
            if (x.length === 1) {
              return x[0] as ShipToCustomer;
            }
            const defaultCustomer = x.find((c) => c.isDefault);
            if (defaultCustomer) {
              return defaultCustomer as ShipToCustomer;
            }
            return null;
          })
        ),
        this.store.select(ConfigurationSelectors.isInternal)
      ),
      filter(
        ([{ cartResult }, shipToCustomer, isInternal]) =>
          shipToCustomer !== null &&
          !isInternal &&
          cartResult?.shipToAddressNo === null
      ),
      map(([{ cartResult }, shipToCustomer, isInternal]) =>
        CartActions.updateShipTo({
          shipToCustomer,
          isEdited: false,
          isCartUpdate: true,
        })
      )
    )
  );
  preselectShiptoIfOnlyOneExsitsForCustomer$ = createEffect(() =>
    this.action$.pipe(
      ofType(
        CustomerActions.selectCustomer,
        CustomerActions.findCustomersSuccess,
        CartActions.selectOpenCartSuccess
      ),
      withLatestFrom(
        this.store
          .select(BranchSelectors.selectedBranch)
          .pipe(map((x) => x.code)),
        this.store.select(CustomerSelectors.selectedCustomer)
      ),
      filter(
        ([_, branchCode]) => branchCode !== undefined && branchCode !== null
      ),
      switchMap(([action, branchCode, customer]) => {
        const customerSearch: CustomerSearch = {
          branchCode,
          customerNumber: customer?.customerNumber,
          customerType: "WE",
        };
        if (action.type === CartActions.selectOpenCartSuccess.type) {
          customerSearch.customerNumber = action.cartResult.customerNumber;
          customerSearch.branchCode = action.cartResult.branchCode;
        }
        return this.customerService.searchCustomers(customerSearch).pipe(
          map((results: Customer[]) => {
            if (results.length === 1) {
              return CustomerActions.setShipToAddress({
                shipToAddress: results[0],
              });
            }
            return CustomerActions.loadShipToCustomersSuccess({
              customers: results,
              openShipToModal: false,
            });
          }),
          catchError(async (error) =>
            CustomerActions.loadShipToCustomersFailure()
          ),
          finalize(() => (this.loaderService.loading = false))
        );
      })
    )
  );

  applyPreselectionToCart$ = createEffect(
    () =>
      combineLatest([
        this.store.select(CustomerSelectors.selectShipToCustomers),
        this.store.select(CartSelectors.selectCartShipToAddressNo),
      ]).pipe(
        withLatestFrom(this.store.select(CustomerSelectors.selectedCustomer)),
        filter(
          ([[_, __], selectedCustomer]) =>
            !isCashCustomer(selectedCustomer.customerNumber)
        ),
        tap(([[shipToCustomers, cartAddressNumber]]) => {
          if (cartAddressNumber === null && shipToCustomers.length === 1) {
            this.store.dispatch(
              CustomerActions.setShipToAddress({
                shipToAddress: shipToCustomers[0],
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  openShipToModal$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(CustomerActions.openShipToModal),
        withLatestFrom(
          this.store.select(CustomerSelectors.selectShipToCustomers),
          this.store.select(CustomerSelectors.selectShipTo),
          this.store.select(CartSelectors.selectCartCustomerNumber)
        ),
        switchMap(([_, customers, shipToAddress, cartCustomerNumber]) =>
          this.modalService
            .open(
              ShippingAddressModalComponent,
              {
                customers: customers.map((account: Customer) => {
                  const selected =
                    shipToAddress?.addressNumber === account.addressNumber;
                  return {
                    ...account,
                    isSelected: selected,
                    editMode: selected,
                  };
                }),
              },
              {
                size: "lg",
                backdrop: "static",
                keyboard: false,
              }
            )
            .pipe(
              tapResponse(
                (account) => {
                  if (account) {
                    this.store.dispatch(
                      CustomerActions.setShipToAddress({
                        shipToAddress: account,
                      })
                    );
                    if (account.customerNumber === cartCustomerNumber) {
                      this.store.dispatch(
                        CartActions.updateShipTo({
                          shipToCustomer: account,
                          isEdited: account.editMode,
                          isCartUpdate: true,
                        })
                      );
                      this.checkoutFormService.checkoutForm.patchValue(
                        {
                          shippingAndDelivery: {
                            shipTo: {
                              ...account,
                              isEdited: false,
                            },
                          },
                        },
                        { emitEvent: false }
                      );
                      this.checkoutFormService.checkoutForm.controls.shippingAndDelivery.controls.shipTo.markAsDirty();
                      this.checkoutFormService.checkoutForm.updateValueAndValidity();
                    }
                  } else {
                    of({});
                  }
                },
                // dismissed
                () =>
                  this.store.dispatch(CustomerActions.setShipToAddressCancel())
              )
            )
        )
      ),
    { dispatch: false }
  );

  getFavoriteCustomers$ = createEffect(() =>
    this.action$.pipe(
      ofType(ConfigurationActions.getConfigurationSuccess),
      switchMap(() =>
        this.customerService.getFavoriteCustomers().pipe(
          map((favoriteCustomers) =>
            CustomerActions.getFavoriteCustomersSuccess({ favoriteCustomers })
          ),
          catchError((error) =>
            of(CustomerActions.getFavoriteCustomersFailed({ error }))
          )
        )
      )
    )
  );

  addFavoriteCustomer$ = createEffect(() =>
    this.action$.pipe(
      ofType(CustomerActions.addFavoriteCustomer),
      withLatestFrom(this.store.select(BranchSelectors.selectedBranch)),
      switchMap(([{ customerNumber }, branch]) =>
        this.customerService
          .CreateFavouriteCustomer(customerNumber, branch.code)
          .pipe(
            map((customer) =>
              CustomerActions.addFavoriteCustomerSuccess({ customer })
            ),
            catchError((error) =>
              of(CustomerActions.addFavoriteCustomerFailed({ error }))
            )
          )
      )
    )
  );

  removeFavoriteCustomer$ = createEffect(() =>
    this.action$.pipe(
      ofType(CustomerActions.removeFavoriteCustomer),
      withLatestFrom(this.store.select(BranchSelectors.selectedBranch)),
      switchMap(([{ customerNumber }, branch]) =>
        this.customerService
          .DeleteFavouriteCustomer(customerNumber, branch.code)
          .pipe(
            map((customerId) =>
              CustomerActions.removeFavoriteCustomerSuccess({ customerId })
            ),
            catchError((error) => {
              this.toastService.showToast(
                error.errorMessage.message,
                error.errorMessage.type
              );
              return of(
                CustomerActions.removeFavoriteCustomerFailed({ error })
              );
            })
          )
      )
    )
  );

  getPayers$ = createEffect(() =>
    this.action$.pipe(
      ofType(
        CartActions.selectCartCustomerSuccess,
        CustomerActions.findCustomersSuccess,
        CartActions.getCartDataForBranchAndCustomer
      ),
      map(() => CustomerActions.getPayers())
    )
  );

  isIterable = (obj) => {
    if (obj === null || obj === undefined) {
      return false;
    }
    return typeof obj[Symbol.iterator] === "function";
  };

  getStoredreditCards$ = createEffect(() =>
    this.store.select(CustomerSelectors.selectCustomerSelectedShipTo).pipe(
      filter((shipToAccountNo) => shipToAccountNo !== null),
      map((shipToAccountNo) => shipToAccountNo?.addressNumber),
      distinctUntilChanged(),
      switchMap((shipToAccountNo) =>
        this.paymentService.getStoredPaymentMethods(shipToAccountNo).pipe(
          map((storedPaymentMethods) =>
            this.isIterable(storedPaymentMethods)
              ? CustomerActions.getStoredPaymentMethodsSuccess({
                  storedPaymentMethods,
                })
              : CustomerActions.getStoredPaymentMethodsEmpty()
          ),
          catchError((error) => {
            this.toastService.showToast(
              error.errorMessage.message,
              error.errorMessage.type
            );
            return of(CustomerActions.getStoredPaymentMethodsFailed({ error }));
          })
        )
      )
    )
  );

  setSelectedPaymentChoices$ = createEffect(() =>
    this.action$.pipe(
      ofType(CustomerActions.setSelectedPaymentMethod),

      tap(({ paymentMethod }) => {
        if (
          !paymentMethod.startsWith("BillTo") &&
          !paymentMethod.startsWith("CCI2P") &&
          !paymentMethod.startsWith("StoredCC") &&
          !paymentMethod.startsWith("AddedCC")
        ) {
          this.store.dispatch(CustomerActions.clearInviteToPay());
          this.store.dispatch(CustomerActions.setStoredPaymentToken(null));
        }
      }),

      withLatestFrom(
        this.store.select(CartSelectors.selectedCartId),
        this.store.select(CustomerSelectors.inviteToPayEmail),
        this.store.select(CustomerSelectors.inviteToPaySms),
        this.store.select(CustomerSelectors.storedPaymentMethod),
        this.store.select(CartSelectors.selectBillingType)
      ),
      switchMap(
        ([
          { paymentMethod },
          cartId,
          i2pEmail,
          I2PSmsNumber,
          storedToken,
          billingType,
        ]) =>
          this.paymentService
            .setPaymentChoices({
              cartId,
              paymentMethod,
              i2pEmail,
              I2PSmsNumber,
              storedToken,
              billingType,
            })
            .pipe(
              map((cart) =>
                CustomerActions.setSelectedPaymentMethodSuccess({ cart })
              ),
              catchError((error) =>
                of(CustomerActions.setSelectedPaymentMethodFailed({ error }))
              )
            )
      )
    )
  );

  inviteToPayResend$ = createEffect(() =>
    this.action$.pipe(
      ofType(CustomerActions.inviteToPayResend),
      switchMap((action) =>
        this.paymentService.inviteToPayResend(action.i2pResendRequest).pipe(
          map((results) => {
            if (results.success && action.onSuccess) {
              action.onSuccess(results);
            }
            this.toastService.showToast(
              results.errorMessage,
              results.success ? ToastType.Success : ToastType.Error
            );
            return results.success
              ? CustomerActions.inviteToPayResendSuccess()
              : CustomerActions.inviteToPayResendFailed();
          }),
          catchError((error) => {
            this.toastService.showToast(
              error.errorMessage?.message,
              error.errorMessage?.type
            );
            return of(CustomerActions.inviteToPayResendFailed());
          })
        )
      )
    )
  );

  getMscPayersOnBranchOrCustomerChange$ = createEffect(() =>
    this.action$.pipe(
      ofType(
        CartActions.selectCartCustomerSuccess,
        CustomerActions.findCustomersSuccess,
        CustomerActions.selectCustomer,
        CustomerActions.setDefaultCustomer,
        BranchActions.selectBranch
      ),
      withLatestFrom(
        this.store.select(BranchSelectors.selectedBranch),
        this.store.select(CustomerSelectors.selectedCustomer)
      ),
      filter(
        ([_, branch, customer]) =>
          Boolean(branch?.code) && Boolean(customer?.customerNumber)
      ),
      switchMap(([_, branch, customer]) => this.getMscPayers(customer, branch))
    )
  );

  syncShipToFromCart$ = createEffect(() =>
    combineLatest([
      this.store.select(CartSelectors.selectCartShipToCustomer),
      this.store.select(CustomerSelectors.selectCustomerSelectedShipTo),
    ]).pipe(
      withLatestFrom(this.store.select(CartSelectors.selectCartId)),
      filter(
        ([[cartShipTo, customerShipTo], cartId]) =>
          JSON.stringify(cartShipTo) !== JSON.stringify(customerShipTo) &&
          cartId !== blankId &&
          cartId !== undefined &&
          cartId !== null &&
          !customerShipTo &&
          cartShipTo?.addressNumber === customerShipTo?.addressNumber
      ),
      map(([[shipToAddress]]) =>
        CustomerActions.setShipToAddress({ shipToAddress })
      )
    )
  );

  getStoredPaymentMethods$ = createEffect(() =>
    this.action$.pipe(
      ofType(CustomerActions.getCreditCards),
      switchMap(({shipToAccountNo}) =>
        this.paymentService.getStoredPaymentMethods(shipToAccountNo).pipe(
          map((storedPaymentMethods) =>
            this.isIterable(storedPaymentMethods)
              ? CustomerActions.getStoredPaymentMethodsSuccess({
                  storedPaymentMethods,
                })
              : CustomerActions.getStoredPaymentMethodsEmpty()
          ),
          catchError((error) => {
            this.toastService.showToast(
              error.errorMessage.message,
              error.errorMessage.type
            );
            return of(CustomerActions.getStoredPaymentMethodsFailed({ error }));
          })
        )
      )
    )
  );

  constructor(
    private checkoutFormService: CheckoutFormService,
    private action$: Actions,
    private toastService: ToastService,
    private customerService: CustomerService,
    private paymentService: PaymentService,
    private store: Store<AppState>,
    private loaderService: LoaderService,
    private ngModalService: NgbModal,
    private modalService: ModalService,
    private loggerService: LoggerService
  ) {}

  getMscPayers(customer: Customer, branch: Branch) {
    const payerSearch = {
      ...new MscPayerSearch(),
      CustomerNumber: customer.customerNumber,
      BranchCode: branch.code,
      AccountGroups: ["Z001", "Z002"],
    };
    return this.customerService.getMscPayers(payerSearch).pipe(
      filter((searchResult) => searchResult.payers.length > 0),
      switchMap((searchResult) => {
        // If there's only one payer, use that
        if (searchResult.payers.length === 1) {
          return of(
            CustomerActions.getMscPayersSuccess({
              payerNumber: searchResult.payers[0].payerNumber,
              mscCardNumber: searchResult.mscCardNumber,
            })
          );
        }
        // If there's more than one payer, use modal to select the payer
        else {
          const defaultPayer =
            searchResult.payers.find((p) => p.isDefault) ||
            searchResult.payers[0];

          const modalInputs = {
            mscCardNumber: searchResult.mscCardNumber,
            payerOptions: searchResult.payers,
            payer: defaultPayer,
          };

          return this.modalService
            .open(MscPayersModalComponent, modalInputs)
            .pipe(
              map(
                (modalResults: {
                  payerNumber: string;
                  mscCardNumber: string;
                }) => CustomerActions.getMscPayersSuccess(modalResults)
              ),
              catchError(() =>
                of(
                  CustomerActions.getMscPayersSuccess({
                    payerNumber: defaultPayer.payerNumber,
                    mscCardNumber: searchResult.mscCardNumber,
                  })
                )
              )
            );
        }
      }),
      catchError((error) => of(CustomerActions.getMscPayersFailed({ error })))
    );
  }
}

